import React, { useContext } from 'react'
import { getTextTypeCard } from '../../utils/functions'
import { StateController } from '../../App'

function DynamicBanner() {
  const {context} = useContext(StateController)
  
  return (
    <div className={`mc-dynamic-banner-container`}>
      <img src={`/assets/images/dynamic-banner${window.innerWidth > 992 ? '-desktop':''}.png`} alt="" />
      <div className='mc-dynamic-banner-container__labels'>
        <h2>Tarjetas {getTextTypeCard(context)} Mastercard AV Villas</h2>
        <p><strong>Paga con Mastercard</strong> y disfruta beneficios exclusivos para tu día a día.</p>
      </div>
    </div>
  )
}

export default DynamicBanner