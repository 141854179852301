import React, { useEffect, useState, useContext } from 'react'
import { StateController } from './../../App'

import Container from '../../layouts/Container/Container'
import PageHeaders from '../atoms/PageHeaders'
import AwardsGallery from '../organisms/AwardsGallery'
import Preloader from '../atoms/Preloader'
import { getLevel } from '../../utils/functions'

// import { getMaximumBlock } from '../../utils/functions'

function Awards() {
  const [awards, setAwards] = useState(false)
  const [awardsRoulette, setAwardsRoulette] = useState(false)
  
  const {context} = useContext(StateController)
  const [canRedem, setCanRedem] = useState(false)
  const [tab, setTab] = useState(1)
  const [alreadyRunner, setAlreadyRunner] = useState(false)
  const [completeAwards, setCompleteAwards] = useState(false)

  useEffect(() => {
    if (context && context.awards) {
      setAwards(
        context.awards[`block_${context.block}_level_${getLevel(context)}`] ? context.awards[`block_${context.block}_level_${getLevel(context)}`].awards : []
      )
      setCanRedem(
        context.awards[`block_${context.block}_level_${getLevel(context)}`]?.canRedem
      )

      if (context.awards && context.awards[`extra_${context.block}`]) {
        setCompleteAwards(context.awards[`extra_${context.block}`].awards)
      }
    }
    
  }, [context])

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  const goToGoal = () => {
    setTab(1)
  }

  const goToRoulette = () => {
    setTab(2)
    refreshSavedAwards()
  }

  const refreshSavedAwards = () => {
    if (localStorage.getItem('rouletteSaved')) {
      const saved = JSON.parse(localStorage.getItem('rouletteSaved'))
      setAlreadyRunner(saved)

      if (saved && completeAwards) {
        const awardSelected = completeAwards.filter((item) => item.id === saved.award_id)
        console.log("DEBO PODER VER EL AWARD GUARDADO", awardSelected)
        setAwardsRoulette(awardSelected)
      }
    }
  }

  useEffect(() => {
    refreshSavedAwards()
  }, [])

  console.log("Context", context)
  console.log("awardsRoulette", awardsRoulette)

  return (
    <div className='mc-page awards slow_ani'>
      <PageHeaders
        title="<span>Tus bonos</span><br />"
        text={canRedem ? '¡Cumpliste tu Meta! <br /> Ahora elige el bono de tu preferencia y ¡disfruta!' : "¡Alcanza tu meta y elige el bono que más te guste!"}
      />

      <Container>

        {alreadyRunner &&
          <div className='tabs-awards'>
            <div className={`tabs-awards__item ${tab === 1 ? 'active' : ''}`} onClick={goToGoal}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M6.98047 13.6267V19.6267C6.98047 19.91 6.88464 20.1475 6.69297 20.3392C6.5013 20.5309 6.2638 20.6267 5.98047 20.6267C5.69714 20.6267 5.45964 20.5309 5.26797 20.3392C5.0763 20.1475 4.98047 19.91 4.98047 19.6267V4.62671C4.98047 4.34338 5.0763 4.10588 5.26797 3.91421C5.45964 3.72254 5.69714 3.62671 5.98047 3.62671H13.1555C13.3888 3.62671 13.5971 3.70171 13.7805 3.85171C13.9638 4.00171 14.0805 4.19338 14.1305 4.42671L14.3805 5.62671H18.9805C19.2638 5.62671 19.5013 5.72254 19.693 5.91421C19.8846 6.10588 19.9805 6.34338 19.9805 6.62671V14.6267C19.9805 14.91 19.8846 15.1475 19.693 15.3392C19.5013 15.5309 19.2638 15.6267 18.9805 15.6267H13.8055C13.5721 15.6267 13.3638 15.5517 13.1805 15.4017C12.9971 15.2517 12.8805 15.06 12.8305 14.8267L12.5805 13.6267H6.98047ZM14.6305 13.6267H17.9805V7.62671H13.5555C13.3221 7.62671 13.1138 7.55171 12.9305 7.40171C12.7471 7.25171 12.6305 7.06004 12.5805 6.82671L12.3305 5.62671H6.98047V11.6267H13.4055C13.6388 11.6267 13.8471 11.7017 14.0305 11.8517C14.2138 12.0017 14.3305 12.1934 14.3805 12.4267L14.6305 13.6267Z" fill="#3A3A3A"/>
              </svg>
              Bonos por meta
            </div>
            <div className={`tabs-awards__item ${tab === 2 ? 'active' : ''}`} onClick={goToRoulette}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#21272A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" stroke="#21272A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M4.92969 4.93005L9.16969 9.17005" stroke="#21272A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M14.8281 14.83L19.0681 19.07" stroke="#21272A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M14.8281 9.17005L19.0681 4.93005" stroke="#21272A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M14.8281 9.17001L18.3581 5.64001" stroke="#21272A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M4.92969 19.07L9.16969 14.83" stroke="#21272A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
              Bonos por ruleta
            </div>
            <div className='step-position slow_ani' style={{"left": `${tab === 1 ? '0%' : '50%'}`}}></div>
          </div>
        }
        
        {tab === 1 &&
          <div>
            {awards
              ? <AwardsGallery
                  awardBoxType={1}
                  itemsPerLine={3}
                  limit={false}
                  limitButtonText="Ver más comercios"
                  showStock={false}
                  list={awards}
                  steps={false}
                  canRedem={canRedem}
                  twoStepAuth={false}
                  redemtionText="Redimir bono"
                  termText="Descripción breve. TyC"
                  termLink="/terminos-y-condiciones"
              />
              : <Preloader
                  type="section"
                  invert
                />
            }
          </div>
        }

        
        {alreadyRunner && tab === 2 && 
          <div className='center-awards'>
            {awardsRoulette
              ? <AwardsGallery
                  awardBoxType={1}
                  itemsPerLine={3}
                  limit={false}
                  limitButtonText="Ver más comercios"
                  showStock={false}
                  list={awardsRoulette}
                  steps={false}
                  canRedem={canRedem}
                  twoStepAuth={false}
                  redemtionText="Redimir bono"
                  termText="Descripción breve. TyC"
                  termLink="/terminos-y-condiciones"
              />
              : <Preloader
                  type="section"
                  invert
                />
            }
          </div>
        }
      </Container>
    </div>
  )
}
export default Awards