import React, { useContext, useEffect, useState } from 'react'
import { StateController } from './../../App'

import Container from '../../layouts/Container/Container'
import PageHeaders from '../atoms/PageHeaders'
import PrimaryBanner from '../molecules/PrimaryBanner'
import Button from '../atoms/Button'
import Levels from '../molecules/Levels'
import Preloader from '../atoms/Preloader'
import DynamicBanner from '../molecules/DynamicBanner'
import { getPrice, getLevelName, getLevel, getTextTypeCard, sendDataLayer, getLevelPercentByOne } from '../../utils/functions'
import Roulette from '../atoms/Roulette'

// components
import FullScreenModal from '../molecules/FullScreenModal'
import Wheel from '../molecules/Wheel'

function Progress() {
  const {context} = useContext(StateController)
  const [showPushGoal, setShowPushGoal] = useState(false)
  const [levelWinner, setLevelWinner] = useState(false)
  const [showRoulette, setShowRoulette] = useState(false) // pass to true?

  const [progress1, setProgress1] = useState(0)
  const [progress2, setProgress2] = useState(0)
  const [canRedem1, setCanRedem1] = useState(false)
  const [canRedem2, setCanRedem2] = useState(false)

  const [rouleteAwards, setRouleteAwards] = useState(false)
  const [completeAwards, setCompleteAwards] = useState(false)
  const [rouletteWinner, setRouletteWinner] = useState(false)

  const [hasRouletteAward, setHasRouletteAward] = useState(false)
  const [showRouletteOnboarding, setShowRouletteOnboarding] = useState(false)

  const [alreadyRunner, setAlreadyRunner] = useState(false)
  const [goalRoulette, setGoalRoulette] = useState(false)
  const [progressRoulette, setProgressRoulette] = useState(false)

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }, [])

  useEffect(() => {
    if (context) {
      if (context.tracing && context.tracing[`winner_${context.block}_level_${getLevel(context)}`] && context.tracing[`winner_${context.block}_level_${getLevel(context)}`] === 1) {
        setLevelWinner(true)
      }

      if (context.firstTime && context.tracing && !context.tracing.extra) {
        setShowPushGoal(true)
      }

      if (context.tracing) {
        setProgress1(
          Number(getLevelPercentByOne(context, 1))
        )
        setProgress2(
          Number(getLevelPercentByOne(context, 2))
        )
        setCanRedem1(
          context.awards[`block_${context.block}_level_1`]?.canRedem
        )
        setCanRedem2(
          context.awards[`block_${context.block}_level_2`]?.canRedem
        )
        setProgressRoulette(
          context.tracing[`amount_extra_${context.block}`]
        )
      }
      
      if (context.tracing[`winner_extra_${context.block}`]) {
        console.log("Ganador ruleta!")
        setRouletteWinner(true)
      }

      if (context.awards && context.awards[`extra_${context.block}`]) {
        setCompleteAwards(context.awards[`extra_${context.block}`].awards)

        let pre = []
        for (let i = 0; i < context.awards[`extra_${context.block}`].awards.length; i++) {
          const awrd = context.awards[`extra_${context.block}`].awards[i]

          if (
            awrd.name == 'Totto' ||  
            awrd.name == 'Velez' || 
            awrd.name == 'Alkosto' || 
            awrd.name == 'Jumbo' || 
            awrd.name == 'Éxito' || 
            awrd.name == 'Crepes & Wafles' || 
            awrd.name == 'Frisby' || 
            awrd.name == 'Pepeganga'
          ) {
            pre.push({
              award_id: awrd.id,
              award_name: awrd.name,
              award_image: `rul-${awrd.image}`,
              award_value: awrd.price
            })
          }
          
        }
        setRouleteAwards(pre)
      }

      const hasRoulette = context.redemtions.find((item) => item.block === 3)
      if (hasRoulette) setHasRouletteAward(true)

      if (localStorage.getItem('rouletteSaved')) {
        setAlreadyRunner(
          JSON.parse(localStorage.getItem('rouletteSaved'))
        )
      }

      if (context.user) {
        setGoalRoulette(
          context.user[`goal_extra_${context.block}`]
        )
      }

      if (!localStorage.getItem('onboardingviewed')) {
        setShowRouletteOnboarding(true)
      }

    }
  }, [context])

  const openLevelModal = () => {
    sendDataLayer({datalayer:{ event: "progress", text: "Decidir ya", link: "/" }, repeat: false})
    setShowPushGoal(true)
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  }

  const openModalRoulette = () => {
    setShowRoulette(true)
  }

  console.log("completeAwards", completeAwards)
  console.log("rouleteAwards", rouleteAwards)
  console.log("goalRoulette", goalRoulette)

  return (
    <div className='mc-page progress slow_ani'>

      <FullScreenModal
        show={showPushGoal}
        setShowModal={setShowPushGoal}
        context={context}
        steps={
          [
            {
              title: "¿Listo para comenzar?<br /> <span>Tu primera meta está aquí</span><br />",
              text: "Alcánzala y podrás redimir un bono en tu comercio favorito o seguir acumulando para ganar mucho más."
            }, 
            {
              title: "<span>¡Tu meta, tus reglas!</span><br /> Decide hasta cuanto quieres ganar",
              text: "Acumula tus compras y alcanza el nivel al que quieres llegar:",
              winnerTitleLevel1: "<span>¡Tu meta, tus reglas!</span><br /> ¡Felicidades! Has alcanzado tu meta de nivel 1",
              winnerSubtitleLevel1: "¿Quieres redimir tu bono, o aceptas la meta de nivel 2 para mejorar tu premio?"
            }
          ]
        }
      />

      <FullScreenModal
        show={showRouletteOnboarding}
        setShowModal={setShowRouletteOnboarding}
        isRoulette={true}
        awards= {rouleteAwards}
        completeAwards={completeAwards}
        rouletteWinner={false}
        alreadyRunner={false}
        onlyOnboarding={true}
        goalRoulette={goalRoulette}
      />

      {(!hasRouletteAward && context) && 
        <FullScreenModal
          show={showRoulette}
          setShowModal={setShowRoulette}
          isRoulette={true}
          awards= {rouleteAwards}
          completeAwards={completeAwards}
          rouletteWinner={rouletteWinner}
          alreadyRunner={alreadyRunner}
          goalRoulette={goalRoulette}
        />
      }

       

      <h1 className='mc-page-title'>Tu nivel</h1>

      {context
        ? <Container>
          <p>{}</p>
            <Levels
              levels={
                [
                  {
                    image: 'level-1.png',
                    title: getLevelName(1),
                    subtitle: 'Meta',
                    amount: getPrice(context.user[`goal_${context.block}_level_1`]),
                    active: getLevel(context) === 1 ? true : false
                  },
                  {
                    image: 'level-2.png',
                    title: getLevelName(2),
                    subtitle: 'Meta',
                    amount: getPrice(context.user[`goal_${context.block}_level_2`]),
                    active: getLevel(context) === 2 ? true : false
                  }
                ]
              }
            />
          </Container>
        : <Preloader
            type="section"
            invert
          />
      }

      {context
        ? <>
            <h1 className='mc-page-title'>Tu progreso</h1>
            <h2 className='mc-page-title'>
              Llevas: <strong>{getPrice(context.tracing ? context.tracing[`amount_${context.block}`] : 0 )}</strong> de <br /><strong>{getPrice(context.user[`goal_${context.block}_level_${getLevel(context)}`])}</strong>
            </h2>
            <h3 className='mc-page-title'>Tienes hasta el <span>15/11/2024</span> para cumplir tu meta.</h3>
            
          </>
        : <Preloader
            type="section"
            invert
          />
      }

      {context
        ? <Container>

            <div className='mc-levels-container-v2'>
              <div className='mc-levels-container-v2__line'>

                <div className={`mc-levels-container-v2__line--item lvl-1 ${canRedem1 ? 'check' : ''} ${getLevel(context) === 1 ? 'active' : ''}`}>
                  <img src={`/assets/images/level-1.png`} />
                  <img src="/assets/images/check_level.png" className='check-level' />
                </div>
                <div className={`mc-levels-container-v2__line--item lvl-2 ${canRedem2 ? 'check' : ''} ${getLevel(context) === 2 ? 'active' : ''}`}>
                  <img src={`/assets/images/level-2.png`} />
                  <img src="/assets/images/check_level.png" className='check-level' />
                </div>
                <div className={`mc-levels-container-v2__line--item lvl-extra ${canRedem2 ? 'active': ''}`}>
                  <img src={`/assets/images/extra.png`} />
                  <img src="/assets/images/check_level.png" className='check-level' />
                </div>

                <div
                  className='mc-levels-container-v2__line--progress-1'
                  style={{"width": `${progress1 > 98 ? 45 : progress1 / 2}%`}}
                ></div>
                <div 
                  className='mc-levels-container-v2__line--progress-2'
                  style={{"width": `${progress2 / 2}%`}}
                ></div>

              </div>
            </div>
          </Container>
        : <Preloader
            type="section"
            invert
          />
      }
      
      {context
        ? <h4 className='mc-page-title'>Tus compras tomarán <span>7</span> días en verse reflejadas. Última actualización: <span>{context.tracing ? context.tracing.date_update : 'Sin actualizar'}</span></h4>
        : <Preloader
            type="section"
            invert
          />
      }

      <div className='mc-mini-divider' />
      {context &&
        <h5 className='mc-page-title'>
          {levelWinner
            ? `${(getLevel(context) === 2 && canRedem2) ? 'Una vez cumplas tu meta podrás redimir tu premio.' : '¿Qué prefieres hacer ahora? ¿Ir por un premio más alto o redimir este bono? La decisión es tuya.'}`
            : `${getLevel(context) === 1 ? 'Cuando alcances tu meta de nivel 1, podrás decidir si quieres ganar un premio más alto o redimir tu bono.' : 'Una vez cumplas tu meta podrás redimir tu premio.'}`
          }
        </h5>
      }

      <Container>
        {
          (getLevel(context) === 2 && canRedem2)
            ? <Button
                type="primary"
                text={'Redimir bono'}
                status={'active' }
                link={"/premios"}
              />
            : <Button
                type="primary"
                text={levelWinner ? 'Decidir ya' : 'Decidir al cumplir la meta'}
                status={levelWinner ? 'active' : 'inactive'}
                link={levelWinner ? false : "/premios"}
                onClick={levelWinner ? openLevelModal : false}
              />
        }
      </Container>
      {/* <div className='mc-max-divider' /> */}
      
      <Container>
        <PrimaryBanner
          className="banner-divider"
          image={getTextTypeCard(context) === 'Débito' ? 'cards_debito.png' : getTextTypeCard(context) === 'Crédito' ? 'cards_credito.png': getTextTypeCard(context) === 'Débito y Crédito' ? 'cards_all_segment.png':'cards_all_segment.png'}
        />
      </Container>

      
      {!localStorage.getItem('rouletteSaved') && 
        <div className='action-init-roulette'>
          <PageHeaders
            title={`<span>¿Quieres ganar más?</span><br /> ¡Descubre cómo!`}
            text={`Realiza mínimo <strong>${goalRoulette}</strong> transacciones por internet y podrás ganar un bono adicional.`}
          />

          <div className='action-init-roulette__title'>
            Llevas <span>{progressRoulette}</span> de <span>{goalRoulette}</span>
          </div>

          <div className='action-init-roulette__banner'>
            <Roulette
              chosenAward={false}
              awards={rouleteAwards}
              run={{}}
              disable={true}
            />
            <img src="/assets/images/dynamic-roulette.png" />
          </div>

          <div className='action-init-roulette__subtitle'>
            <p>*Tienes hasta el 15 de noviembre de 2024 para cumplir este reto.</p>
          </div>
          
          <div className='action-init-roulette__actions'>
            <Button
              type="primary"
              text={rouletteWinner ? 'Girar la ruleta ahora' : 'Gira al cumplir tu meta'}
              status={rouletteWinner ? 'active' : 'inactive'}
              onClick={rouletteWinner ? openModalRoulette : false}
            />
          </div>
        </div>
      }
      



      {(hasRouletteAward || localStorage.getItem('rouletteSaved')) &&
        <div style={{"backgroundImage": `url(/assets/images/bg-has-roulette${window.innerWidth > 992 ? '-desktop':''}.png)`}}>
          <Container className="has-roulete-award">
            <h2>
              <span>¡Ya giraste la ruleta!</span>< br />
              Encuentra tu bono en el catálogo de premios
            </h2>
            <Button
              type="primary"
              text="Descargar bono"
              status="active"
              link={`/${!hasRouletteAward ? 'premios' : 'mis-redenciones'}`}
            />
          </Container>
        </div>
      }

      <Container>
        {context &&
          <DynamicBanner context={context} />
        }
      </Container>

      {/* {context
        ? <PageHeaders
            title="<span>Gira y gana</span> con nuestra<br /> ruleta de premios!"
            text={`Alcanza <strong>${getPrice(context.user[`goal_extra_${context.block}`])}</strong> en tus compras digitales y gira la ruleta.`}
          /> 
        : <Preloader
            type="section"
            invert
          />
      }

      {context
        ? <h2 className='mc-page-title'>Llevas: <strong>{getPrice(context.tracing ? context.tracing[`amount_extra_${context.block}`] : 0)}</strong> de <br /><strong>{getPrice(context.user[`goal_extra_${context.block}`])}</strong></h2>
        : <Preloader
            type="section"
            invert
          />
      } */}

      {/* <Container>
        <PrimaryBanner
          image="ruleta.png"
        />
        <div className='mc-medium-divider' />
        <Button
          type="primary"
          text="Redimir bono"
          status="inactive"
          link="/premios"
        />
      </Container> */}
      
    </div>
  )
}
export default Progress