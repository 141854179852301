import React from 'react'

function Levels({
  levels,
  level,
  type,
  percent
}) {

  return (
    <div className={`mc-levels-container mc-levels-type-${type} mc-levels-count-${levels.length}`}>
      <div className='mc-levels-container--cards'>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="17" viewBox="0 0 24 17" fill="none">
          <rect x="0.56" y="0.911563" width="22.88" height="14.88" rx="1.04" stroke="#3A3A3A" strokeWidth="1.12"/>
          <rect x="0.800781" y="4.35156" width="22.4" height="4.8" fill="#3A3A3A"/>
        </svg>
      </div>
      {levels.map((lvl, index) => {

        return (
          <div key={index} className={`mc-levels-container__level lvl-${index+1} ${lvl.active ? 'active' : ''}`}>
            <img src={`/assets/images/${lvl.image}`} />
            <div>Nivel {type !== 'table' ? index+1 : ''}</div>
            {/* <div>{lvl.title}</div> */}

            {type !== 'table'
              ? <div>{lvl.subtitle} {lvl.amount}</div>
              : <div>{lvl.subtitle} <span>{lvl.amount}</span></div>
            }

            {type == 'table' && <div>Gana: <span>{lvl.award}</span></div>}
            {type === 'profile' &&
              <span>Nivel {lvl.title}</span>
            }
            {type === 'profile' &&
              <div className='mc-levels-container__level--percent-container'>
                <div className='mc-levels-container__level--percent-container--progress'  style={{"width": `${index+1 === 2 ? ((levels[0].percent - 100) > 100 ? 100 : (levels[0].percent - 100)) : (lvl.percent > 100 ? 100 : lvl.percent)}%`}}></div>
                {index+1 === 2
                  ? <span>{((levels[0].percent - 100) > 100 ? (levels[0].percent - 100) : (levels[0].percent - 100)) < 0 ? 0 : (levels[0].percent - 100) > 100 ? (levels[0].percent - 100).toFixed(0) : (levels[0].percent - 100).toFixed(0)}%</span>
                  : <span>{(lvl.percent > 100 ? 100 : lvl.percent) < 0 ? 0 : (lvl.percent > 100 ? 100 : lvl.percent).toFixed(0)}%</span>
                }
                
              </div>
            }
          </div>
        )
      })}

      {type === 'timeline' &&
        <div 
          className='mc-levels-container__progress'
          style={{"width": `${level === 2 ? (percent-8) : (percent-8)/2}%`}}
        >
          <span  style={{"left": `${level === 2 ? (percent-8) : (percent-8)/2}%`, "width": `${level === 2 ? ((percent-8) / 2) : ((percent-8) / 2)/2}%`}}></span>
        </div>
      }
    </div>
  )
}

export default Levels