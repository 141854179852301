import React from 'react'
import { useNavigate } from 'react-router-dom'

function Button({
  type,
  text,
  loading,
  status,
  onClick,
  link,
  redemtion,
  isNext,
  goBack,
  className
}) {
  const navigate = useNavigate()
  const handler = () => {
    console.log("Click", link)

    if (status === 'active' && !loading) {
      link
        ? navigate(link)
        : onClick()
    }
  }

  return (
    <div
      className={`mc-button-container mc-button-container__${type} ${goBack ? 'isBack' : ''} ${className}`}
      onClick={handler}
    >
      {goBack 
        ? <button onClick={handler} className='mc-button-container__backButton'>
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
              <path d="M10.332 3.6665H0.998697" stroke="#3A3A3A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M3.66797 6.33317L1.0013 3.6665" stroke="#3A3A3A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M3.66797 1L1.0013 3.66667" stroke="#3A3A3A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </button>
        : <button className={`slow_ani mc-button-container__status--${status}`}>
            {status === 'inactive' &&
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" className='inactive-block-icon'>
                <path d="M3.89453 7.20575V5.85893C3.89453 3.62995 4.56794 1.81848 7.93498 1.81848C11.302 1.81848 11.9754 3.62995 11.9754 5.85893V7.20575" stroke="#505050" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7.93352 12.9298C8.8633 12.9298 9.61704 12.176 9.61704 11.2463C9.61704 10.3165 8.8633 9.56274 7.93352 9.56274C7.00375 9.56274 6.25 10.3165 6.25 11.2463C6.25 12.176 7.00375 12.9298 7.93352 12.9298Z" stroke="#505050" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M11.3003 15.2867H4.56626C1.87263 15.2867 1.19922 14.6133 1.19922 11.9197V10.5729C1.19922 7.87922 1.87263 7.20581 4.56626 7.20581H11.3003C13.994 7.20581 14.6674 7.87922 14.6674 10.5729V11.9197C14.6674 14.6133 13.994 15.2867 11.3003 15.2867Z" stroke="#505050" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            }
            {loading
              ?
              redemtion
                ? <><img src='/assets/images/preloader_1.gif' alt='loader' className='inline-loader' /> Cargando</>
                : <img src='/assets/images/preloader_1.gif' alt='loader' className='inline-loader' />
              : text
            }
            {isNext &&
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                <mask id="mask0_7413_2940" maskUnits="userSpaceOnUse" x="0" y="0" width="17" height="16">
                  <rect x="0.5" width="16" height="16" fill="#D9D9D9"/>
                </mask>
                <g mask="url(#mask0_7413_2940)">
                  <path d="M3.83203 8H13.1654" stroke="#F4F4F4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M10.5 10.6667L13.1667 8" stroke="#F4F4F4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M10.5 5.33337L13.1667 8.00004" stroke="#F4F4F4" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
              </svg>
            }
          </button>
      }
      
    </div>
  )
}

export default Button