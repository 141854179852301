import React from 'react'

import Container from '../../layouts/Container/Container'
import LoginForm from '../organisms/LoginForm'
import SliderRotator from '../organisms/SliderRotator'
import { sendDataLayer, getCardsSlides } from '../../utils/functions'

function Login({
  autologin,
  hash,
  setAutologinPreloader,
  autologinPreloader
}) {

  const start = () => {
    window.scrollTo({top: document.querySelector('.start-button').offsetTop, left: 0, behavior: 'smooth' })
  }

  return (
    <div className='mc-page login slow_ani'>

      <div className='login-desktop-container'>
        <div className='mc-pre-login'>
          <div>
            <strong>Cumple tu meta</strong> de compras y podrás ganar con tus Tarjetas Débito y Crédito Mastercard AV Villas
            <img src="/assets/images/hombre-frutas.png" alt="" />
          </div>
        </div>

        <div className='mc-section-login-slider'>
          <SliderRotator
            mobileItemsToShow={1}
            desktopItemsToShow={3}
            style={1}
            ext={true}
            arrows={false}
            list={getCardsSlides()}
          />
        </div>

        <button className='start-button' onClick={start}>
          Comienza
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.00001 2.58398C8.41422 2.58398 8.75001 2.91977 8.75001 3.33398L8.75001 10.8563L10.1362 9.47016C10.4291 9.17727 10.904 9.17727 11.1968 9.47016C11.4897 9.76305 11.4897 10.2379 11.1968 10.5308L8.54337 13.1843C8.40674 13.3278 8.21383 13.4173 8.00001 13.4173C7.86338 13.4173 7.73528 13.3808 7.62495 13.3169C7.58118 13.2917 7.53942 13.2616 7.50043 13.2267C7.48938 13.2168 7.47863 13.2067 7.46818 13.1961C7.4655 13.1935 7.46285 13.1907 7.46022 13.188L4.80301 10.5308C4.51012 10.2379 4.51012 9.76305 4.80301 9.47016C5.09591 9.17726 5.57078 9.17726 5.86367 9.47016L7.25001 10.8565L7.25001 3.33398C7.25001 2.91977 7.5858 2.58398 8.00001 2.58398Z" fill="#F2F2F2"/>
          </svg>
        </button>

        <div className="mc-login-container">
          <LoginForm
            title="Título corto"
            type="credentials"
            setAutologinPreloader={setAutologinPreloader}
            autologinPreloader={autologinPreloader}
            fields={[
              {field: "document",label: "Ingresa los últimos 5 dígitos de tu documento",placeholder: "Ingresa aquí tus datos",maxlength: 5, separated:false},
              {field: "birthdate",label: "Fecha de nacimiento",placeholder: "DD/MM/AAAA"},
              {field: "terms",label: "He leído y acepto los",termsText: "términos y condiciones",termsLink: "/terminos-y-condiciones"} // DO NOT CHANGE THIS LINE
            ]}
            buttonText="Ingresar"
            background="#fff"
            autologin={autologin}
            hash={hash}
          />
        </div>
      </div>

      <Container className="benefits-login">
        <h1>No te pierdas los <span>beneficios</span> que nuestros aliados tienen para ti</h1>
          
        <div className='benefits-login__items'>

          <div className='benefits-login__items--item'>
            <div className='benefits-login__items--item__image' style={{"backgroundImage": `url(/assets/images/éxito.svg)`}}></div>
            <div className='benefits-login__items--item__labels'>
              <div>Éxito</div>
              <div>20% <span>Dto.</span></div>
              <div>en web o app</div>
              <div><a onClick={(e) => sendDataLayer({datalayer:{ event: "alliances", text: e.target.textContent, link: e.target.href }, repeat: false})} href="https://www.mastercard.com.co/es-co/consumidores/conozca-nuestras-ofertas-y-promociones/every-day-value.html">Aplica términos y condiciones.</a></div>
            </div>
          </div>
          <div className='benefits-login__items--item'>
            <div className='benefits-login__items--item__image' style={{"backgroundImage": `url(/assets/images/carulla.svg)`}}></div>
            <div className='benefits-login__items--item__labels'>
              <div>Carulla</div>
              <div>25% <span>Dto.</span></div>
              <div>en web o app</div>
              <div><a onClick={(e) => sendDataLayer({datalayer:{ event: "alliances", text: e.target.textContent, link: e.target.href }, repeat: false})} href="https://www.mastercard.com.co/es-co/consumidores/conozca-nuestras-ofertas-y-promociones/every-day-value.html">Aplica términos y condiciones.</a></div>
            </div>
          </div>
          <div className='benefits-login__items--item'>
            <div className='benefits-login__items--item__image' style={{"backgroundImage": `url(/assets/images/farmatodo.svg)`}}></div>
            <div className='benefits-login__items--item__labels'>
              <div>Farmatodo</div>
              <div>$25.000 <span>cop</span></div>
              <div>en web o app</div>
              <div><a onClick={(e) => sendDataLayer({datalayer:{ event: "alliances", text: e.target.textContent, link: e.target.href }, repeat: false})} href="https://www.mastercard.com.co/es-co/consumidores/conozca-nuestras-ofertas-y-promociones/every-day-value.html">Aplica términos y condiciones.</a></div>
            </div>
          </div>
          <div className='benefits-login__items--item'>
            <div className='benefits-login__items--item__image' style={{"backgroundImage": `url(/assets/images/mercadolibre.svg)`}}></div>
            <div className='benefits-login__items--item__labels'>
              <div>Mercado Libre</div>
              <div>10% <span>Dto.</span></div>
              <div>en web o app</div>
              <div><a onClick={(e) => sendDataLayer({datalayer:{ event: "alliances", text: e.target.textContent, link: e.target.href }, repeat: false})} href="https://www.mastercard.com.co/es-co/consumidores/conozca-nuestras-ofertas-y-promociones/every-day-value.html">Aplica términos y condiciones.</a></div>
            </div>
          </div>
          <div className='benefits-login__items--item'>
            <div className='benefits-login__items--item__image' style={{"backgroundImage": `url(/assets/images/logo-tostao.svg)`}}></div>
            <div className='benefits-login__items--item__labels'>
              <div>Tostao</div>
              <div>25% <span>Dto.</span></div>
              <div>en tienda</div>
              <div><a onClick={(e) => sendDataLayer({datalayer:{ event: "alliances", text: e.target.textContent, link: e.target.href }, repeat: false})} href="https://www.mastercard.com.co/es-co/consumidores/conozca-nuestras-ofertas-y-promociones/every-day-value.html">Aplica términos y condiciones.</a></div>
            </div>
          </div>


        </div>
        
      </Container>

    </div>
  )
}
export default Login