import React from 'react'
import FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import { Button } from '@nextui-org/react'

export function sendDataLayer(config) {
  if (config.repeat) {

    window?.dataLayer?.push(config.datalayer)
  } else {
    let dl = true
    if (window && window.dataLayer) {
      window.dataLayer.forEach(layer => {
        if (layer.event === config.datalayer.event) dl = false
      })
    }
    if (dl) {

      window.dataLayer?.push(config.datalayer)
    }
  }
}

export function exportExcel({ excelData, fileName,text }) {

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8'; const fileExtension = '.xlsx';
  const exportToExcel = async () => {
      const ws = XLSX.utils.json_to_sheet(excelData);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
  }
  return (
    <Button onClick={() => exportToExcel(fileName)}>
      {text}
    </Button>
  )
}

export function getPrice(value, onlyNumber) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  return onlyNumber ? formatter.format(value).replace(',','.').replace('$','') : formatter.format(value).replace(',','.')
}

export function getPercent(total, amount, onlyNumber) {

  return amount === 0 ? 0 : `${((amount*100) / total).toFixed(1)}${onlyNumber ? '' : '%'}`
}

export function uncodePrice(context) {
  const price = context.user[`award_${context.block}`] / 1000

  return price * 1000
}

export function isLoginPage(location) {

  return (
    !location.pathname.includes('progreso') || 
    !location.pathname.includes('premios') || 
    !location.pathname.includes('mecanica') ||
    !location.pathname.includes('redenciones') || 
    !location.pathname.includes('alianzas')
  ) ? false : true
}

export function slugify(text) {

  return text.toString().toLowerCase()
    .replace(/(\w)'/g, '$1')
    .replace(/[^a-z0-9_-]+/g, '-')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
}

export const arrNum = (num) => {
  let arr = []
  for (let i = 0; i < num; i++) {
    arr.push(i)
  }
  return arr
}

export function formatCurrencyNumber(amount) {

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })

  return formatter.format(amount).replace('$','').replace(',','.')
}

export function getMaximumBlock(block, blocks) {

  return (block || 1) > (blocks ? blocks : 1) ? blocks : (block || 1)
}

export function itsFiltered(gallery, amount) {
  let returner = false

  if (gallery <= Number(amount)) returner = true
  

  if (amount === 40000 && gallery !== 40000) {
    returner = false
  } else if (amount === 50000 && gallery < 50000) {
    returner = false
  } else if (amount === 80000 && gallery === 50000) {
    returner = false
  } else if (amount === 100000 && (gallery === 40000 || gallery === 80000)) {
    returner = false
  } else if (amount === 150000 && (gallery === 40000 || gallery === 80000)) {
    returner = false
  } else if (amount === 200000 && (gallery === 40000 || gallery === 80000)) {
    returner = false
  } else if (amount === 250000 && (gallery === 40000 || gallery === 80000)) {
    returner = false
  } else if (amount === 300000 && (gallery === 40000 || gallery === 80000)) {
    returner = false
  } else if (amount === 500000 && (gallery === 40000 || gallery === 80000)) {
    returner = false
  }

  return returner
}

export function getLevelName(id) {
  return id == 1 ? '1' : '2'
}

export function getLevel(context) {
  return (context && context.tracing && context.tracing.level) ? context.tracing.level : 1
}

export function getLevelPercent(context, level) {
  let percent = 0

  if (context && context.tracing) {
    let amount = context.tracing[`amount_${context.block}`]
    let goal = context.user[`goal_${context.block}_level_${level}`]

    if (getLevel(context) != level) {
      percent = 0
    } else {
      percent = (amount*100) / goal
    }
  }

  return percent > 0 ? `${percent.toFixed(1)}%` : '0%'
}

export function getLevelPercentByOne(context, level) {
  let percent = 0

  if (context && context.tracing) {
    let amount = context.tracing[`amount_${context.block}`]
    let goal1 = context.user[`goal_${context.block}_level_1`]
    let goal2 = context.user[`goal_${context.block}_level_2`]

    if (level === 1) {
      percent = (amount*100) / goal1
    }
    if (level === 2) {
      percent = ((amount-goal1)*100) / goal2
    }
  }

  return percent
}

export function getCardsSlides(context) {
  return [
    {
      id: 1,
      image: "1.png",
      title: "Conoce tu meta",
      desc: "<strong>Consulta cuánto necesitas acumular</strong> en compras, sigue tu progreso y prepárate para redimir los premios que te esperan al alcanzarla."
    },
    {
      id: 2,
      image: "2.png",
      title: 'Elige cuánto</br>quieres ganar',
      desc: "<strong>Decide en qué nivel quieres estar</strong>, entre más subas de nivel mayor será tu recompensa."
    },
    {
      id: 3,
      image: "3.png",
      title: `Paga todo</br>con tu Tarjeta ${context ? getTextTypeCard(context, true) : ''}`,
      desc: `Avanza hacia tu meta usando tu Tarjeta ${context ? getTextTypeCard(context, true) : ''} en cada compra del <strong>15 de septiembre de 2024 al 15 de noviembre de 2024</strong>.`
    },
    {
      id: 4,
      image: "4.png",
      title: 'Gana y disfruta',
      desc: '<strong>Redime tu premio</strong> en el comercio que más te guste. ¡Empieza a ganar ahora!'
    },
    {
      id: 5,
      image: "5.png",
      title: '¡Acepta los retos</br>y gana más!',
      desc: 'Completa los retos sorpresa para participar por <strong>premios extra</strong>. ¡Demuestra que puedes con todo!'
    },
    {
      id: 6,
      space: true
    }
  ]
}

export function getTextTypeCard(context) {  
  if (context && context.user.segment) {
    if (context?.user?.segment === 'debito0' ) {
      return 'Débito'
    }else if(context?.user?.segment === '0credito' ){
      return 'Crédito'
    }else if(context?.user?.segment === 'debitocredito'){
      return 'Débito y Crédito'

    }
    
  }
  return false

}